import { createSet, isDisplayPeriod } from "./index";
import { IS_USING_ALD_MSG_COLLECTION, NG_WORD } from "constants/streaming";
import { streamingState } from "modules/streaming";
import type { ResGetStreamingKey } from "@spwn/types/functions";
import type { ProductData } from "@spwn/types/firebase/firestore";

/**
 * check if user can watch video
 * @param streamingKey
 * @param video
 */
export const canWatchVideo = (
  streamingKey: ResGetStreamingKey,
  vid: string,
  // @ts-expect-error TS2322
  ticketId: string = null
) => {
  if (streamingKey.isError) {
    return false;
  }
  // @ts-expect-error TS18048
  if (ticketId !== null && !streamingKey.ticketIds.includes(ticketId)) {
    return false;
  }
  // if streaming has started
  return (
    (Boolean(streamingKey.cookies) &&
      // @ts-expect-error TS18048
      streamingKey.cookies.hasOwnProperty(vid)) ||
    // if streaming is free open
    (streamingKey.hasTickets && Boolean(streamingKey.url)) ||
    // if streaming page is before open or streaming has started
    // @ts-expect-error TS18048
    (Boolean(streamingKey.videoIds) && streamingKey.videoIds.includes(vid))
  );
};

/**
 * return on sale video ids from event ticket
 * @param tickets
 */
export const getOnSaleVideoIds = (tickets: ProductData[]): string[] => {
  const onSaleTickets = tickets.filter((ticket) =>
    isDisplayPeriod(
      ticket.display,
      ticket.releaseDateTime,
      ticket.expiredDateTime
    )
  );
  let onSaleVideos: string[] = [];
  onSaleTickets.forEach((ticket) => {
    // @ts-expect-error TS2769
    onSaleVideos = onSaleVideos.concat(ticket.videoIds);
  });
  return createSet(onSaleVideos);
};

/**
 * テキストエリアに入力された文字数をカウントする。
 * 引数にはカウントしたいinputのidと、カウント数を出力するエレメントのid、通常コメントかスパチャかのbool値を指定する
 * @param inputElementId
 * @param outputElementId
 */
export const countTextLength = (
  inputElementId: string,
  outputElementId: string
) => {
  const inputElement = document.getElementById(
    inputElementId
  ) as HTMLInputElement;
  const { length } = inputElement.value;
  // @ts-expect-error TS2531
  document.getElementById(outputElementId).innerText = length + "/50";
};

/**
 * コメント欄のエレメントを取得する
 */
export const getMsgListElement = () => {
  const innerElement = document.getElementById("msg-list-layout");
  const listElement = document.getElementById("msg-list-inner");
  return { innerElement, listElement };
};
/**
 * 取得したコメント欄のエレメントを基に、コメント欄の一番下までの高さを取得する
 * @param innerElement
 * @param listElement
 */
// @ts-expect-error TS7006
export const getMsgListValue = (innerElement, listElement) => {
  const msgAreaHeight = innerElement.scrollHeight;
  const msgDispleyFlame = listElement.clientHeight;
  const bottomPosition = msgAreaHeight - msgDispleyFlame;
  return bottomPosition;
};
/**
 * コメント欄の表示位置を一番下まで移動する
 */
export const msgScrollBottom = () => {
  const { innerElement, listElement } = getMsgListElement();
  const bottomPosition = getMsgListValue(innerElement, listElement);
  // @ts-expect-error TS18047
  listElement.scrollTop = bottomPosition;
};

/**
 * check if goods page is present
 * HOTFIX ライブコマースだけかどうかのために使用
 * @param eventId
 */
export const HAS_GOODS_PAGE = (eventId: string) => {
  switch (eventId) {
    case "200816-boymen-newsingleevent":
    case "200822-boymen-newsingleevent":
    case "200822-boymen-newsingleevent2":
      return false;
    default:
      return true;
  }
};

/**
 * save current playing time to session storage
 * @param vid
 * @param currentTime
 */
export const saveCurrentTimeToSessionStorage = (
  vid: string,
  currentTime: string
) => {
  // const now = new Date();
  // now.setTime(now.getTime() + (5 * 24 * 60 * 60 * 1000)); // store for 5 days
  // const expires = `expires=${now.toUTCString()}`
  // document.cookie = `${vid}=${currentTime};${expires};path=/`
  sessionStorage.setItem(`playHeadPosition${vid}`, currentTime);
};

/**
 * load play head from session storage
 * @param vid
 */
// @ts-expect-error TS7006
export const loadCurrentTimeFromSessionStorage = (vid) => {
  const t = sessionStorage.getItem(`playHeadPosition${vid}`);
  if (t) {
    return Math.max(Number(t) - 5, 0); // offset -5s from play head
  } else {
    return 0;
  }
};

/**
 * 禁止ワードが含まれているかチェックする
 * @param { string } value
 * @return boolean
 */
export const isForbiddenWord = (value: string, ngWords: string[]): boolean => {
  try {
    const ngWordsList = NG_WORD.concat(ngWords).map(escapeRegExp);

    // make pattern like [(a)|(abc)|(cd)]
    const pattern = "(" + ngWordsList.join(")|(") + ")";

    // g: global, i: ignore case
    const rgx = new RegExp(pattern, "gi");

    return rgx.test(value);
  } catch (error) {
    // NGワード設定側のバリデーションが完璧ではないので
    // 仮にエラーになったら握り潰してコメントを許容する。エラー通知はさせたい。
    console.error(error);
    return false;
  }
};

/**
 * 特殊文字をエスケープする
 * @see https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 */
const escapeRegExp = (string: string) => {
  // $&はマッチした部分文字列全体を意味します
  return string.replace(/[.*+?^=!:${}()|[\]\/\\]/g, "\\$&"); // eslint-disable-line no-useless-escape
};

/**
 * 配信ページに入ったときに配信する動画を決定する
 * ticketが2つ以上videoを持っている場合、配信中 > 配信前 の優先度で、一番初めの要素のvideoIdを採用する
 * @param eventVideoMap
 * @param videoIds
 */
export const extractDefaultVideoId = (
  eventVideoMap: streamingState["eventVideoMap"],
  videoIds: string[]
) => {
  const openVideoIds = [];
  const closedVideoIds = [];
  for (const videoId of videoIds) {
    const video = eventVideoMap?.[videoId];
    if (video?.isOpen === undefined || video?.isOpen) {
      openVideoIds.push(videoId);
    } else {
      closedVideoIds.push(videoId);
    }
  }
  return openVideoIds.length > 0 ? openVideoIds[0] : closedVideoIds[0];
};

export const getCommentCollectionPath = (
  eventId: string,
  videoId: string,
  isShardingEnabled: boolean,
  shardId: number
) => {
  if (isShardingEnabled) {
    return `/chat/${eventId}/videoIds/${videoId}/shards/${shardId}/msgs`;
  }
  return IS_USING_ALD_MSG_COLLECTION(eventId)
    ? `/chat/${eventId}/msgs`
    : `/chat/${eventId}/videoIds/${videoId}/msgs`;
};

export const getCommentCountPath = (
  eventId: string,
  videoId: string,
  isShardingEnabled: boolean,
  shardId: number
) => {
  if (isShardingEnabled) {
    return `/chat/${eventId}/videoIds/${videoId}/shards/${shardId}`;
  }
  return IS_USING_ALD_MSG_COLLECTION(eventId)
    ? `/chat/${eventId}`
    : `/chat/${eventId}/videoIds/${videoId}`;
};
