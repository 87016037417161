/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { useState } from "react";
import { useI18n } from "hooks/i18n/i18n";
import { useSelector } from "react-redux";
import { Store } from "store";
import { Link } from "react-router-dom";
import {
  getEventDisplayInfo,
  getDateTimeString,
  isSmartPhone,
  EventInfo,
} from "utility";
import ConfirmCvsPayment from "components/ConfirmCvsPayment";
import type { UnProcessData } from "components/ConfirmCvsPayment";
import icon_video from "designs/images/icon_video.png";
import sample_state from "designs/img/sample_state.jpg";
import sample_state_02 from "designs/img/sample_state_02.jpg";

import { changeStyleWithHosting } from "utility/hosting";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { SubContents } from "components/templates/SubContents";
import { Breadcrumb } from "components/atoms/Breadcrumb";
import styled from "@emotion/styled";
import { LinkButton } from "../../styles";
import type { MyProductData, PayType } from "@spwn/types";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import type {
  EventVideo,
  ActiveTransaction,
} from "@spwn/types/firebase/firestore";

interface CvsInfo {
  cvsCode: CVSCode;
  ReceiptNo: string;
  ConfNo: string;
}

interface PhoneInfo {
  phoneCode: PhoneCode;
  phoneURL: string;
  payType: PayType;
}

const Container = styled.div`
  width: 740px;
  max-width: 90%;
  margin: 0 auto;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .my_video_layout {
    padding: 5%;
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    .my_video_01 {
      .my_video_heading {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #d4d4d4;
        .date {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
        .title {
          margin-bottom: 10px;
          color: #0bc7cd;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
        .artist {
          font-size: 12px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .my_video_purchase {
        display: flex;
        justify-content: space-between;
        .layout_l {
          width: 48%;
          @media screen and (min-width: 768px) {
            width: 44%;
          }
          .thumbnail_layout {
            position: relative;
            .video_img {
              width: 100%;
            }
            .video_state {
              position: absolute;
              top: 5px;
              left: 5px;
              width: 45px;
              @media screen and (min-width: 768px) {
                width: 70px;
              }
            }
            .icon_video {
              position: absolute;
              top: 50%;
              right: 50%;
              width: 55px;
              margin-top: -20px;
              margin-right: -27px;
              @media screen and (min-width: 768px) {
                width: 110px;
                margin-top: -40px;
                margin-right: -55px;
              }
            }
            .time {
              position: absolute;
              bottom: 5px;
              right: 5px;
              padding: 5px;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.7);
            }
          }
        }
        .layout_r {
          width: 48%;
          @media screen and (min-width: 768px) {
            width: 52%;
          }
          .ticket_name {
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.5em;
            @media screen and (min-width: 768px) {
              font-size: 16px;
            }
          }
          .ticket_expired {
            margin-bottom: 10px;
            color: red;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.3em;
            @media screen and (min-width: 768px) {
              margin-bottom: 25px;
              font-size: 14px;
            }
            br {
              @media screen and (min-width: 768px) {
                display: none;
              }
            }
          }
          .price {
            margin-bottom: 15px;
            font-size: 16px;
            text-align: right;
            font-weight: bold;
            letter-spacing: 1px;
            @media screen and (min-width: 768px) {
              margin-bottom: 30px;
              font-size: 20px;
            }
          }
          .btn_pc {
            display: none;
            padding: 12px 0;
            color: #fff;
            font-weight: bold;
            text-align: center;
            background-color: #00c2ae;
            @media screen and (min-width: 768px) {
              display: block;
            }
          }
        }
      }
      .btn_sp {
        display: block;
        padding: 12px 0;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        background-color: #00c2ae;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
  .my_video_02 {
    padding: 5%;
    border-top: 1px solid #d4d4d4;
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    .my_video_purchase {
      display: flex;
      justify-content: space-between;
      .layout_l {
        width: 48%;
        @media screen and (min-width: 768px) {
          width: 44%;
        }
        .thumbnail_layout {
          position: relative;
          .video_img {
            width: 100%;
          }
          .video_state {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 45px;
            @media screen and (min-width: 768px) {
              width: 70px;
            }
          }
          .icon_video {
            position: absolute;
            top: 50%;
            right: 50%;
            width: 55px;
            margin-top: -20px;
            margin-right: -27px;
            @media screen and (min-width: 768px) {
              width: 110px;
              margin-top: -40px;
              margin-right: -55px;
            }
          }
          .time {
            position: absolute;
            bottom: 5px;
            right: 5px;
            padding: 5px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .layout_r {
        width: 48%;
        @media screen and (min-width: 768px) {
          width: 52%;
        }
        .ticket_name {
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5em;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
        .ticket_expired {
          margin-bottom: 10px;
          color: red;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            margin-bottom: 25px;
            font-size: 14px;
          }
          br {
            @media screen and (min-width: 768px) {
              display: none;
            }
          }
        }
        .price {
          margin-bottom: 15px;
          font-size: 16px;
          text-align: right;
          font-weight: bold;
          letter-spacing: 1px;
          @media screen and (min-width: 768px) {
            margin-bottom: 30px;
            font-size: 20px;
          }
        }
        .btn_pc {
          display: none;
          padding: 12px 0;
          color: #fff;
          font-weight: bold;
          text-align: center;
          background-color: #00c2ae;
          @media screen and (min-width: 768px) {
            display: block;
          }
        }
      }
    }
    .btn_sp {
      display: block;
      padding: 12px 0;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      background-color: #00c2ae;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  .unpurchased {
    .my_video_purchase .layout_r .btn_pc,
    .btn_sp {
      background-color: #ff0000 !important;
    }
    .unpurchased_state {
      width: 100%;
      margin-bottom: 5px;
      @media screen and (min-width: 768px) {
        width: 160px;
      }
      img {
        width: 100%;
      }
    }
  }
`;

export const AccountVodTicket: React.FC = () => {
  const { t } = useI18n();

  const myVideoInfo = useSelector(
    (state: Store) => state.streaming.myVideoInfo
  );

  const myUnprocessData = useSelector(
    (state: Store) => state.ticket.myUnprocessData
  );
  const activeTransactionMap = useSelector(
    (state: Store) => state.purchase.activeTransactionMap
  );

  const [cvsInfo, setCvsInfo] = useState<CvsInfo>({
    // @ts-expect-error TS2322
    cvsCode: null,
    // @ts-expect-error TS2322
    ReceiptNo: null,
    // @ts-expect-error TS2322
    ConfNo: null,
  });
  // @ts-expect-error TS2345
  const [unprocessData, setUnprocessData] = useState<UnProcessData[]>(null);
  const [isOpenConfirmCvsPayment, setIsOpenConfirmCvsPayment] =
    // @ts-expect-error TS2345
    useState<boolean>(null);
  const [phoneInfo, setPhoneInfo] = useState<PhoneInfo>({
    // @ts-expect-error TS2322
    phoneCode: null,
    // @ts-expect-error TS2322
    phoneURL: null,
    // @ts-expect-error TS2322
    payType: null,
  });
  // @ts-expect-error TS2345
  const [selectedOrderId, setSelectedOrderId] = useState<number>(null);

  const isSp = isSmartPhone();
  const menuArchiveText =
    changeStyleWithHosting().commonSettings.menu.archive.text;

  /**
   * open cvs payment info
   * @param transactionData
   */
  const openConfirmCvsPayment = (transactionData: ActiveTransaction) => {
    // 確認画面用のデータを作成する
    const { orderId } = transactionData;
    const unprocessDataList: UnProcessData[] = [];
    for (const eid of Object.keys(myVideoInfo.myVideoMap)) {
      // @ts-expect-error TS2339
      const { event, tickets, goods, unprocessOrderIds } =
        myVideoInfo.myVideoMap[eid];
      if (!unprocessOrderIds.includes(String(orderId))) continue;
      // @ts-expect-error TS7006
      const ticketList = tickets.filter((el) => el.orderId === String(orderId));
      // @ts-expect-error TS7006
      const goodsList = goods.filter((el) => el.orderId === String(orderId));
      const productList = ticketList.concat(goodsList);
      if (productList.length !== 0) {
        unprocessDataList.push({
          event,
          ticketList,
          goodsList,
        });
      }
    }

    if (transactionData.payType === "CVS") {
      setCvsInfo({
        // @ts-expect-error TS2322
        ConfNo: transactionData.confNo,
        // @ts-expect-error TS2322
        ReceiptNo: transactionData.receiptNo,
        cvsCode: transactionData.convenience as CVSCode,
      });
    } else if (transactionData.payType === "Phone") {
      setPhoneInfo({
        phoneCode: transactionData.phoneCode as PhoneCode,
        // @ts-expect-error TS2322
        phoneURL: transactionData.phoneURL,
        payType: transactionData.payType,
      });
    }
    setUnprocessData(unprocessDataList);
    setIsOpenConfirmCvsPayment(!isOpenConfirmCvsPayment);
    setSelectedOrderId(Number(orderId));
  };

  const closeCvsConfirmModal = () => {
    setIsOpenConfirmCvsPayment(!isOpenConfirmCvsPayment);
  };

  // fetching mytickets
  if (!myVideoInfo) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  const { myVideoMap, videoDataMap } = myVideoInfo;
  const classes = styles();

  // there is no viewable videos
  if (Object.keys(myVideoMap).length === 0) {
    return (
      <SubContents>
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            [null, t("common.routes.vod")],
          ]}
        />

        <div className="mypage_video_layout">
          <p className="have_not_ticket" css={classes.noTicket}>
            {t("vod.emptyLive")}
          </p>
          <LinkButton>
            <Link to={`/vod-events`}>
              {t("vod.archiveList", { archiveName: menuArchiveText })}
            </Link>
          </LinkButton>
        </div>
      </SubContents>
    );
  }

  return (
    <SubContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.vod")],
        ]}
      />
      <div css={classes.VODroot}>
        <ConfirmCvsPayment
          isOpen={isOpenConfirmCvsPayment}
          close={closeCvsConfirmModal}
          unprocessData={unprocessData}
          cvsCode={cvsInfo.cvsCode}
          ReceiptNo={cvsInfo.ReceiptNo}
          ConfNo={cvsInfo.ConfNo}
          phoneCode={phoneInfo.phoneCode}
          phoneURL={phoneInfo.phoneURL}
          orderId={selectedOrderId}
          activeTransactionMap={activeTransactionMap}
        />

        {/* <div className="mypage_video_layout"> */}
        {Object.keys(myVideoMap).map((eid, i) => {
          const myTicketInfo = myVideoMap[eid];

          // including all unprocess data, so need to filter by vod ticket id
          const unprocessDataList = myUnprocessData
            ? myUnprocessData.filter(
                (el) =>
                  // @ts-expect-error TS18048
                  myTicketInfo.unprocessOrderIds.indexOf(String(el.orderId)) >=
                  0
              )
            : [];
          // @ts-expect-error TS18048
          const eventInfo = getEventDisplayInfo(myTicketInfo.event);

          // if fes event, unsupport my live page
          if (eventInfo.isFesEvent) return null;

          return (
            <Container key={i}>
              {/*
               // @ts-expect-error TS18048 */}
              {myTicketInfo.tickets.map((ticket, i) => {
                const unpurchased = ticket.status === "UNPROCESS";
                const unpurchasedClassName = unpurchased ? "unpurchased" : "";
                // vod ticketの決済待ちはチケットごとに1つしかないはず
                // @ts-expect-error TS2322
                const unprocessData: ActiveTransaction[] =
                  unprocessDataList.length !== 0
                    ? unprocessDataList.filter((upData) => {
                        return String(upData.orderId) === ticket.orderId;
                      })
                    : null;
                const openConfirmVideoCvsPayment = unprocessData
                  ? // @ts-expect-error TS2345
                    () => openConfirmCvsPayment(unprocessData[0])
                  : null;

                // videos related to one ticket
                // @ts-expect-error TS18048
                return ticket.videoIds.map((vid, j) => {
                  const video = videoDataMap[vid];
                  // fail safe
                  if (!video) {
                    // eslint-disable-next-line array-callback-return
                    return; // TODO: null , undefined , "" あたりを返せばいいと思うけど未検証なのでdisable
                  }

                  const hasHeaderVideoElement = i === 0 && j === 0;
                  const videoClassName = hasHeaderVideoElement
                    ? "my_video_01"
                    : "my_video_02";
                  const isOpen = video.isOpen === undefined || video.isOpen;

                  // create component related to video
                  const videoActionButtonComponent = (
                    <VideoActionButton
                      // @ts-expect-error TS2322
                      eventId={eventInfo._id}
                      // @ts-expect-error TS2322
                      videoId={video._id}
                      unpurchased={unpurchased}
                      isSp={isSp}
                      // @ts-expect-error TS2322
                      openConfirmCvsPayment={openConfirmVideoCvsPayment}
                      isOpenStreamingPage={
                        eventInfo.isOpenStreamingPage && isOpen
                      }
                    />
                  );

                  const videoComponent = (
                    <React.Fragment>
                      <Video
                        ticket={ticket}
                        video={video}
                        unpurchased={unpurchased}
                        videoActionButtonComponent={
                          isSp ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            videoActionButtonComponent
                          )
                        }
                        eventInfo={eventInfo}
                      />
                      {isSp ? (
                        videoActionButtonComponent
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  );

                  return hasHeaderVideoElement ? (
                    <div className="my_video_layout" key={`${i}-${j}`}>
                      <div
                        className={`${videoClassName} ${unpurchasedClassName}`}
                      >
                        <div className="my_video_heading">
                          <p className="date">
                            {eventInfo.isLongEvent
                              ? `${eventInfo.eventOpenDateFull} ~ ${eventInfo.eventEndDateFull}`
                              : `${eventInfo.eventOpenDateFull}(${eventInfo.eventOpenDay})`}
                          </p>
                          <p className="title">{eventInfo.eventTitle}</p>
                          <p className="artist">{eventInfo.artists}</p>
                        </div>
                        {videoComponent}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${videoClassName} ${unpurchasedClassName}`}
                      key={`${i}-${j}`}
                    >
                      {videoComponent}
                    </div>
                  );
                });
              })}
            </Container>
          );
        })}
      </div>
    </SubContents>
  );
};

interface VideoProps {
  ticket: MyProductData;
  video: EventVideo;
  unpurchased: boolean;
  // @ts-expect-error TS7008
  videoActionButtonComponent;
  eventInfo: EventInfo;
}
/**
 * each event ticket video element
 * @param props
 */
const Video = (props: VideoProps) => {
  const { t } = useI18n();
  const classes = styles();
  const { ticket, video, unpurchased, videoActionButtonComponent } = props;
  const videoThumbnail = video.thumbnail || props.eventInfo.thumbnail;

  return (
    <div className="my_video_purchase" css={classes.root}>
      <div css={classes.thumbanail}>
        <img className="video_img" src={videoThumbnail} alt={video.name} />
        {unpurchased ? null : (
          <img
            className="video_state"
            src={sample_state}
            alt={t("vod.purchased")}
          />
        )}
        <img className="video_icon" src={icon_video} alt="" />
      </div>

      <div className="layout_r" css={classes.info}>
        {unpurchased ? (
          <p className="unpurchased_state">
            <img src={sample_state_02} alt="" />
          </p>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <p className="ticket_name">{video.name}</p>
        {!props.eventInfo.isHiddenVODExpiredAt && ticket.vodExpiredAt ? (
          <p className="ticket_expired">
            {t("vod.expirationDate")}
            <br />
            {getDateTimeString(ticket.vodExpiredAt)}
          </p>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <p className="price">¥{ticket.price_jpy.toLocaleString()}</p>
        {videoActionButtonComponent}
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      display: flex;
      justify-content: space-between;
      align-items: start;
    `,
    VODroot: css`
      width: 90%;
      max-width: 980px;
      padding: 40px 0 80px;
      margin: 0 auto;
    `,
    thumbanail: css`
      position: relative;
      width: 48%;
      @media screen and (min-width: 768px) {
        width: 44%;
      }
      .video_img {
        width: 100%;
      }
      .video_state {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 45px;
        @media screen and (min-width: 768px) {
          width: 70px;
        }
      }
      .video_icon {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 55px;
        margin-top: -20px;
        margin-right: -27px;
        @media screen and (min-width: 768px) {
          width: 110px;
          margin-top: -40px;
          margin-right: -55px;
        }
      }
    `,
    info: css`
      width: 48%;
      @media screen and (min-width: 768px) {
        width: 52%;
      }
      .ticket_name {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      .ticket_expired {
        margin-bottom: 10px;
        color: red;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          margin-bottom: 25px;
          font-size: 14px;
        }
      }
      .price {
        /* margin-bottom: 15px; */
        font-size: 16px;
        text-align: right;
        font-weight: bold;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          margin-bottom: 30px;
          font-size: 20px;
        }
      }
    `,
    noTicket: css`
      padding: 90px 50px 50px 50px;
      font-weight: bold;
      text-align: center;
    `,
  };
};

/**
 * each video button
 * @param props
 */
const VideoActionButton = (props: {
  eventId: string;
  videoId: string;
  unpurchased: boolean;
  isSp: boolean;
  isOpenStreamingPage: boolean;
  openConfirmCvsPayment: () => void;
}) => {
  const { t } = useI18n();
  const classesVideoActionButtonStyles = videoActionButtonStyles();
  const buttonClassName = props.isSp ? "btn_sp" : "btn_pc";
  return (
    <div css={classesVideoActionButtonStyles.root}>
      {props.unpurchased ? (
        <p className={buttonClassName} onClick={props.openConfirmCvsPayment}>
          {t("vod.confirmSettlementInfo")}
        </p>
      ) : props.isOpenStreamingPage ? (
        <Link
          className={buttonClassName}
          to={{
            pathname: `/events/${props.eventId}/streaming`,
            state: { vid: props.videoId },
          }}
        >
          {t("vod.watchLive")}
        </Link>
      ) : (
        <p className={`${buttonClassName} disenable`}>{t("vod.beforeLive")}</p>
      )}
    </div>
  );
};

const videoActionButtonStyles = () => {
  return {
    root: css`
      .btn_sp {
        margin-top: 16px;
      }
    `,
  };
};
