/** @jsxRuntime classic /
/** @jsx jsx */
import icon_play from "../../designs/images/icon_video_play.svg";
import { loadCurrentTimeFromSessionStorage } from "../../utility/streaming";
import { create, isPlayerSupported } from "amazon-ivs-player";
import type { THEOSetting } from "@spwn/types/firebase/firestore";
import { StreamingType } from "modules/streaming";

export const AddDoNotScrollStyle = () => {
  document.documentElement.style.overflow = "hidden";
  document.body.style.overflow = "hidden";
  document.documentElement.style.touchAction = "none";
  document.body.style.touchAction = "none";
};

export const RemoveDoNotScrollStyle = () => {
  document.documentElement.removeAttribute("style");
  document.body.removeAttribute("style");
};

export const createAWSPlayer = () => {
  if (isPlayerSupported) {
    try {
      const params = {
        domain: window.location.hostname, // Cache Busting for multi-domain CORS
      };
      const player = create({
        wasmWorker:
          "https://public.spwn.jp/spwn-steaming/amazon-ivs-player-1.2.0/dist/assets/amazon-ivs-wasmworker.min.js?" +
          new URLSearchParams(params),
        wasmBinary:
          "https://public.spwn.jp/spwn-steaming/amazon-ivs-player-1.2.0/dist/assets/amazon-ivs-wasmworker.min.wasm?" +
          new URLSearchParams(params),
      });
      player.setAutoplay(false); // play only if when LL-mode is selected
      player.setLiveLowLatencyEnabled(true);
      player.attachHTMLVideoElement(
        // @ts-expect-error TS2345
        document.querySelector(".aws-video-player")
      );
      return player;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    return null;
  }
};

/**
 * playerを作成する
 * @see https://docs.theoplayer.com/api-reference/web/theoplayer.playerconfiguration.md
 */
export const createPlayer = (
  poster: string,
  // player作成後に、sourceを更新するケースがある
  url: string | "" | null,
  setting: THEOSetting,
  streamingType: StreamingType,
  vid: string | null = null
) => {
  const win = window as unknown;
  // @ts-expect-error TS7008
  const { THEOplayer } = win as { THEOplayer };
  const element = document.querySelector(".theoplayer-container");
  const player = new THEOplayer.Player(element, {
    allowNativeFullscreen: setting.allowNativeFullscreen,
    // initialRendition: 'low',
    /**
     * https://portal.theoplayer.com/app/bdac9bf2-8a66-460c-944d-8235876c0e5c/players/35668166-03eb-4900-836d-ee6548da9492
     * 有効期限: November 30, 2024
     */
    license:
      "sZP7IYe6T6P6IQIlCLCrIOklIQ0zFSar0la-TuC_TmzZIueo3KfzTSXl0Sa6FOPlUY3zWokgbgjNIOf9fKPeCD0rCKCZFS113lC-3uCiCZzr3uxeFSRZ0Lh_3LIl0QhkCZfVfK4_bQgZCYxNWoryIQXzImf90SbL3Shk0lRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3L5i3uarTu5z3L0oFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya",
    libraryLocation: "https://cdn.jsdelivr.net/npm/theoplayer@2.92.0/",
    // libraryLocation:
    //   "https://cdn.myth.theoplayer.com/fffa7290-b785-46e7-8e75-d9bb4b22c211/",
    mutedAutoplay: "all",
    ui: {
      aspectRatio: "16:9",
      playbackRates: setting.playBackRates,
    },
    allowMixedContent: true,
    pip: setting.enablePiP
      ? {
          visibility: 0.5,
          position: "bottom-right",
          retainPresentationModeOnSourceChange: true,
        }
      : {},
  });
  //player.autoplay = true;
  // player.src = 'https://wowza.peer5.com/live/smil:bbb_abr.smil/playlist.m3u8';
  player.abr.targetBuffer = setting.abr.targetBuffer;
  player.abr.strategy = setting.abr.strategy;
  player.muted = false; // default is unmuted
  player.poster = poster;
  player.preload = "auto";
  if (url !== null || url !== "") {
    player.source = {
      sources: [
        {
          src: url,
          type: "application/x-mpegurl",
          lowLatency: true,
          liveOffset: setting.initialLiveOffset,
          crossOrigin: "use-credentials",
        },
      ],
    };
    if (streamingType === "VOD" && !!vid) {
      const previousPlayHeadPosition = loadCurrentTimeFromSessionStorage(vid);
      if (previousPlayHeadPosition) {
        player.currentTime = previousPlayHeadPosition;
      }
    }
  }

  // THEOplayerの再生ボタンを変更する
  const playButtonContainer = document.getElementsByClassName(
    "theo-big-play-button-svg-container"
  );
  // @ts-expect-error TS2532
  playButtonContainer[0].innerHTML = "<img src=" + icon_play + ' alt=""/>';

  return player;
};
