/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import PlayButton from "./PlayButton";
import { EventInfo, isSmartPhone, replaceBr } from "../../../../utility";
import LiveAnimation from "./LiveAnimation";
import { VideoStatus } from "./VideoStatus";
import { canWatchVideo } from "../../../../utility/streaming";
// @ts-expect-error TS7016
import AnchorLink from "react-anchor-link-smooth-scroll";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { getVideoStreamingStatus } from "../../../../utility/event";
import { EventVideo } from "../../../../../../types/firebase/firestore";
import { ResGetStreamingKey } from "../../../../../../types/functions";
import { useI18n } from "hooks/i18n/i18n";

type Props = {
  displayEvent: EventInfo;
  video: EventVideo;
  streamingKey: ResGetStreamingKey;
  isOpenLiveStreamingPage: boolean;
  goLive: (vid: string) => void;
};

export const EventVideoThumbnail: React.FC<Props> = (props) => {
  const { displayEvent, video, isOpenLiveStreamingPage, goLive, streamingKey } =
    props;
  // @ts-expect-error TS2345
  const canWatch = canWatchVideo(streamingKey, video._id);
  const isVideoOpen = video.isOpen === undefined || video.isOpen;
  const isPlayable =
    canWatch && displayEvent.isOpenStreamingPage && isVideoOpen;

  const videoStateLabel = (
    canWatch: boolean,
    video: EventVideo,
    isOpenLiveStreamingPage: boolean
  ) => {
    switch (getVideoStreamingStatus(canWatch, isOpenLiveStreamingPage, video)) {
      case "Live":
        return <LiveAnimation />;
      case "BeforePlay":
        return <VideoStatus statusText="配信前" />;
      default:
        break;
    }
  };
  const classes = styles();
  const { t } = useI18n();

  return (
    <div css={classes.videoItem}>
      <div css={classes.thumbnailArea}>
        <img
          src={video.thumbnail || displayEvent.thumbnail}
          alt={video.name || displayEvent.title}
        />
        {/*
         // @ts-expect-error TS2345 */}
        {isPlayable && <PlayButton action={() => goLive(video._id)} />}
        {videoStateLabel(canWatch, video, isOpenLiveStreamingPage)}
        {!canWatch && (
          <AnchorLink
            className="purchaseTicket"
            href="#ticket"
            offset={() => {
              return isSmartPhone() ? 30 : 100;
            }}
          >
            <span>
              {t("event.headline.liveStreaming.ticket")}
              <ArrowDropDownCircleIcon
                css={{ marginLeft: "4px", verticalAlign: "sub" }}
                fontSize="small"
              />
            </span>
          </AnchorLink>
        )}
      </div>
      <div css={classes.statusArea}>
        <div css={classes.status}>
          {video.name && <p className="video">{replaceBr(video.name)}</p>}
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    videoItem: css`
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 31%;
        margin-bottom: 20px;
      }
    `,
    thumbnailArea: css`
      border: 1px solid #d4d4d4;
      position: relative;
      img {
        width: 100%;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .purchaseTicket {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        width: 100%;
        font-size: 16px;
        text-align: center;
        span {
          border-bottom: 1px solid #fff;
          padding-bottom: 6px;
        }
      }
    `,
    statusArea: css`
      padding: 12px 0;
      a:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    status: css`
      .video {
        font-size: 16px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
    `,
  };
};
