import flower_3000 from "../designs/images/flowerStand/flower_3000.jpg";
import flower_5000 from "../designs/images/flowerStand/flower_5000.jpg";
import flower_10000 from "../designs/images/flowerStand/flower_10000.jpg";
import { replaceBr, getNowTimestamp } from ".";
import sharedLabel from "../designs/images/label_shared.svg";
import vodLabel from "../designs/images/label_vod.svg";

import { Timestamp } from "@google-cloud/firestore";
import type { StreamingType, TicketLabelStatus } from "@spwn/types";
import type {
  ProductData,
  Event,
  HostingType,
  EventVideo,
} from "@spwn/types/firebase/firestore";
import type { PawItem } from "@spwn/types/firebase/database";
import { i18nextT } from "../hooks/i18n/i18n";
import { SalesStatus } from "@spwn/types/spwn/event";

export type FlowerStandItemInfo = PawItem & {
  pawValue: number;
  flowerListImg: string;
  previewClassName: "middle" | "grand" | "original";
};

/**
 * flower stand
 */
export const getFlowerStandDisplayInfo = (
  flowerStand: PawItem
): FlowerStandItemInfo => {
  const name = replaceBr(flowerStand.name);
  const pawValue = flowerStand.values.paid;
  const flowerListImg =
    pawValue === 3000
      ? flower_3000
      : pawValue === 5000
      ? flower_5000
      : flower_10000;
  const previewClassName =
    pawValue === 3000 ? "middle" : pawValue === 5000 ? "grand" : "original";
  return {
    ...flowerStand,
    name,
    pawValue,
    flowerListImg,
    previewClassName,
  };
};

/**
 * select image from spwn-balus bucket public-public
 */
export const selectImgPath = (
  imgPath: string,
  // @ts-expect-error TS2322
  resolution: "480" | "1280" = null,
  ext: "jpg" | "png" = "jpg"
) => {
  if (!resolution) {
    return imgPath;
  }
  return `${imgPath}_${resolution}.${ext}`;
};

/**
 * in my ticket, display ticket status label
 */
export const getTicketStatusLabel = (
  labelStatus: TicketLabelStatus
): string => {
  switch (labelStatus) {
    case "shared":
      return sharedLabel;
    case "vod":
      return vodLabel;
    default:
      return "";
  }
};

/**
 * ticket
 */

/**
 * check if ticket is vod
 * @param streamingType
 */
export const isVODTicket = (ticket: { streamingType?: StreamingType }) => {
  return ticket.streamingType && ["Live", "VOD"].includes(ticket.streamingType);
};
export const isLiveTicket = (ticket: { streamingType?: StreamingType }) => {
  return ticket.streamingType && ["Live"].includes(ticket.streamingType);
};
export const isArchiveTicket = (ticket: { streamingType?: StreamingType }) => {
  return ticket.streamingType && ["VOD"].includes(ticket.streamingType);
};

/**
 * calc and return vod ticket expired at.
 * @param ticketData
 * @param purchasedAt
 */
export const calcVODExpiredAt = (
  ticketData: ProductData,
  purchasedAt: Timestamp
) => {
  return ticketData.streamingType === "Live"
    ? new Date(ticketData.expiredDateTime.seconds * 1000)
    : ticketData.streamingType === "VOD"
    ? new Date(
        (purchasedAt.seconds + Number(ticketData.vodActiveHours) * 60 * 60) *
          1000
      )
    : null;
};

/**
 * streaming
 */

/**
 * check if streaming is open
 * if event don't have streamingPageOpenTime field, check only hasStreamingPageOpen flag
 */
export const isOpenEventStreamingPage = (event: Event): boolean => {
  const streamingPageOpenTimeSeconds = event.streamingPageOpenTime
    ? event.streamingPageOpenTime.seconds
    : 0;
  return (
    event.hasStreamingPageOpen &&
    streamingPageOpenTimeSeconds <= getNowTimestamp()
  );
};

/**
 * HOTFIX: hide datetime for multiple start time
 * @param eventId
 */
export const IS_HIDE_DATETIME = (eventId: string) => {
  switch (eventId) {
    case "200614-nonsexual":
    case "200616-nonsexual":
    case "200617-nonsexual":
    case "200610-admission":
      return true;
    default:
      return false;
  }
};

/**
 * @function getSalesState
 * @description チケットとグッズの販売状況からイベントの販売ステータスを出力する。
 * @param {SalesStatus} ticketSaleStatus チケットの販売ステータス
 * @param {SalesStatus} goodsSaleStatus グッズの販売ステータス
 * @returns {Object} イベントのステータス
 */
export const getSalesState = (
  ticketSaleStatus: SalesStatus,
  goodsSaleStatus: SalesStatus
) => {
  /**
   * @description 販売ステータスを格納する
   */
  const states: SalesStatus[] = [
    "END",
    "SOLD_OUT",
    "NONE",
    "BEFORE_SALE",
    "ON_SALE",
  ];

  /**
   * @description 以下の様なオブジェクトを作成する
   * { END: 0, SOLD_OUT: 1, NONE: 2, BEFORE_SALE: 3, ON_SALE: 4 }
   */
  const stateSwapMap = states.reduce((obj, state, index) => {
    // @ts-expect-error TS7053
    obj[state] = index;
    return obj;
  }, {});

  // @ts-expect-error TS7053
  const _a = stateSwapMap[ticketSaleStatus];
  // @ts-expect-error TS7053
  const _b = stateSwapMap[goodsSaleStatus];
  const state = states[Math.max(Number(_a), Number(_b))];
  // console.log(ticketSaleStatus, goodsSaleStatus, _a, _b, state)

  switch (state) {
    case "END":
    case "SOLD_OUT":
      return {
        status: "END",
        color: "#fff",
        label: i18nextT("event.status.endSale"),
      };
    case "NONE":
      return {
        status: "NONE",
        color: null,
        label: null,
      };
    case "BEFORE_SALE":
      return {
        status: "BEFORE_SALE",
        color: "#2ee7d2",
        label: i18nextT("event.status.beforeSale"),
      };
    case "ON_SALE":
      return {
        status: "ON_SALE",
        color: "#2ee7d2",
        label: i18nextT("event.status.onSale"),
      };
    default:
      return {
        status: "NONE",
        color: null,
        label: null,
      };
  }
};

/*!
 * event detail page
 */
export const isListByVideo = (eventId: string) => {
  return (
    isTIFEvent(eventId) ||
    ["200911-admission", "21122802-TOFES", "22012202-lvroleplay"].includes(
      eventId
    )
  );
};

export const isTIFEvent = (eventId: string) => {
  return [
    "20100210-tif2020-day1",
    "20100310-tif2020-day2",
    "20100410-tif2020-day3",
    "20100110-tif2020-testcast",
  ].includes(eventId);
};

export const getBelongedHostingType = (hostings: HostingType[]) => {
  // first index is belonged hosting.
  return hostings[0];
};

type VideoStatus = "Live" | "Archive" | "BeforePlay" | "NotPlayable";

/**
 * ビデオの状態を判定する
 */
export const getVideoStreamingStatus = (
  canWatch: boolean,
  isOpenStreamingPage: boolean,
  video: EventVideo
): VideoStatus => {
  if (isOpenStreamingPage) {
    const isVideoOpen = video.isOpen === undefined || video.isOpen;
    const isVideoHasVOD = video.hasVOD === undefined || video.hasVOD;
    if (isVideoOpen) return isVideoHasVOD ? "Archive" : "Live";
    return "NotPlayable";
  }
  if (canWatch) return "BeforePlay";
  return "NotPlayable";
};
