/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type Props = {
  statusText: string;
};

export const VideoStatus: React.FC<Props> = (props) => {
  const classes = styles();
  return (
    <div css={classes.liveStatus}>
      <p>{props.statusText}</p>
    </div>
  );
};

const styles = () => {
  return {
    liveStatus: css`
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      padding: 4px 10px;
      background-color: #696568;
      p {
        color: #fff;
        font-size: 14px;
      }
    `,
  };
};
