/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { Store } from "store";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { modalActions } from "modules/modal";
import { streamingActions } from "modules/streaming";
import { firestoreActions } from "modules/firestore";
import { getNowTimestamp, isDisplayPeriod } from "utility";
import { canWatchVideo, extractDefaultVideoId } from "utility/streaming";
import { changeStyleWithHosting } from "utility/hosting";
import { isListByVideo, isOpenEventStreamingPage } from "utility/event";
import { useI18n } from "hooks/i18n/i18n";
import EventVideoList from "containers/event/eventDetail/EventVideoList";
import { ArchiveVideoSkeleton } from "components/loading/SkeletonLoader";
import { LiveListItemThumbnail } from "components/event/detail/videoList/LiveListItemThumbnail";
import { LiveListItemState } from "components/event/detail/videoList/LiveListItemState";
import { LiveListItemPrice } from "components/event/detail/videoList/LiveListItemPrice";
import { LiveListItemCaution } from "components/event/detail/videoList/LiveListItemCaution";
import type { Ticket } from "@spwn/types/firebase/firestore";

interface Props {
  displayTickets: Ticket[];
}
const EventLiveList: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const dispatch = useDispatch();
  const classesHeadingDesign = headingDesign();
  const classesArchiveVideoDesign = archiveVideoDesign();

  const _pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const isLogined = useSelector((state: Store) => state.auth.user.uid);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const streamingKey = useSelector(
    (state: Store) => state.streaming.streamingKey
  );
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);

  const [isOpenStreamingPage, setIsOpenStreamingPage] = useState(
    displayEvent.isOpenStreamingPage
  );

  const didMount = () => {
    dispatch(
      streamingActions.getStreamingKey.started({ eid: displayEvent.eventId })
    );
    dispatch(
      streamingActions.watchEventVideos.started({
        eventId: displayEvent.eventId,
      })
    );

    // unmount
    return () => {
      dispatch(streamingActions.clearStateByKey("streamingKey"));
      dispatch(streamingActions.clearStateByKey("eventVideoMap"));
      dispatch(firestoreActions.closeChannel({ channel: "eventVideos" }));
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  useEffect(() => {
    // hasStreamingPageOpenがtrueで、streamingPageOpenTimeが未来なら開場までの時間を計算してsetTimeout。
    const nowTimestamp = getNowTimestamp();
    if (
      displayEvent.hasStreamingPageOpen &&
      displayEvent.streamingPageOpenTime &&
      nowTimestamp < displayEvent.streamingPageOpenTime.seconds
    ) {
      const secondsUntilOpen =
        displayEvent.streamingPageOpenTime.seconds - nowTimestamp;
      window.setTimeout(() => {
        setIsOpenStreamingPage(true);
      }, secondsUntilOpen * 1000);
    }
    // displayEvent.isOpenStreamingPageを使わないのは、再計算されない場合があるため。
    // e.g. hasStreamingPageOpen: true, now < streamingPageOpenTime → の時に配信ページに行き戻ってきた場合
    setIsOpenStreamingPage(isOpenEventStreamingPage(displayEvent));
  }, [displayEvent]);

  const _handleButtonAction = (
    videoId: string,
    isOpen: boolean,
    canWatch: boolean
  ) => {
    if (!isLogined) {
      dispatch(modalActions.toggleLoginMethod());
      return;
    }

    if (canWatch) {
      ((isOpenStreamingPage && isOpen) || isAdmin) && goLive(videoId);
      return;
    }

    if (displayEvent.eventSaleStatus === "ON_SALE") {
      if (displayEvent.ticketUrl) {
        // if ticket purchase is external site, open its site
        // @ts-expect-error TS2345
        window.open(displayEvent.ticketUrl, null, "noopener");
        return;
      }
      // go to purchase
      dispatch(push(`/events/${displayEvent.eventId}/ticket`));
      return;
    }
    return;
  };

  const goLive = (vid: string) => {
    dispatch(push(`/events/${displayEvent.eventId}/streaming`, { vid }));
  };

  if (!streamingKey || !eventVideoMap) {
    /**
     * TODO@Kawano
     * デザイン変更に伴いスケルトンローダーも調整する
     */
    return <ArchiveVideoSkeleton />;
  }

  const { isHiddenVODExpiredAt } = displayEvent;
  const { isLotteryTicketSellingMode: _ } = displayEvent;
  // true if event has vod any tickets and streaming page is not open
  const isBeforeOpenStreamingPage = !isOpenStreamingPage;
  // whether or not user purchase ticket, user can enter streaming page if live streaming.
  const isOpenLiveStreamingPage = isOpenStreamingPage && !displayEvent.isClosed;
  // check if event is donation type (now only 200330-istarfes)
  // @ts-expect-error TS2345
  const isDonationEvent = IS_DONATION_EVENT(displayEvent._id);

  // text
  const captionName = isBeforeOpenStreamingPage
    ? "COMING LIVE"
    : isOpenLiveStreamingPage
    ? "LIVE STREAMING"
    : "ARCHIVED LIVE";

  //TODO マーダーミステリー終了後に削除 後ほど修正する用の差分memo
  // if (
  //   pathname.indexOf("-murdermystery") !== -1 &&
  //   displayEvent.hasStreamingPageOpen &&
  //   displayEvent.hasStreamingStarted
  // ) {
  //   return (
  //     <div css={classesHeadingDesign.root}>
  //       <div css={classesHeadingDesign.heading}>
  //         <h2>{captionName}</h2>
  //         <p>{t("event.archiveVideo.caption")}</p>
  //       </div>
  //       <EventVideoList />
  //     </div>
  //   );
  // }

  // TIF events display video list not ticket list
  if (
    isListByVideo(displayEvent.eventId)
    // ライブの表示にeventの販売日を見るべきではないかも。
    // 例えば販売期間を分けた場合、視聴できるライブを持っているのに販売前としてしまって視聴できないという事故が発生する可能性がある。
    // && displayEvent.eventSaleStatus !== 'BEFORE_SALE'
  ) {
    return (
      <div css={classesHeadingDesign.root}>
        <div css={classesHeadingDesign.heading}>
          <h2>{captionName}</h2>
          <p>{t("event.archiveVideo.caption")}</p>
        </div>
        <EventVideoList />
      </div>
    );
  }

  return (
    <div css={classesHeadingDesign.root}>
      <div css={classesHeadingDesign.heading}>
        <h2>{captionName}</h2>
        <p>{t("event.archiveVideo.caption")}</p>
      </div>

      <div css={classesArchiveVideoDesign.root}>
        {props.displayTickets.map((ticket, i) => {
          const defaultVideoId = extractDefaultVideoId(
            eventVideoMap,
            ticket.videoIds
          );
          // @ts-expect-error TS2538
          const video = eventVideoMap[defaultVideoId];
          const _videoId = video?._id;
          let videoThumbnail = video?.thumbnail || displayEvent.thumbnail;
          if (ticket.videoIds.length > 1) {
            videoThumbnail = displayEvent.thumbnail;
          }

          const isOpen = video?.isOpen === undefined || video?.isOpen;
          // Boolean: ビデオを視聴出来るかどうか(チケットを持っているかどうか)判定する
          const canWatch = canWatchVideo(streamingKey, video?._id);
          // Boolean: チケットが販売期間中かどうか判定する
          const _isOnSale =
            displayEvent.eventSaleStatus === "ON_SALE" &&
            isDisplayPeriod(
              ticket.display,
              ticket.releaseDateTime,
              ticket.closeDateTime
            );

          return (
            <div key={i} css={classesArchiveVideoDesign.videoItem}>
              <LiveListItemThumbnail
                displayEvent={displayEvent}
                videoThumbnail={videoThumbnail}
                isOpen={isOpen}
                canWatch={canWatch}
                isOpenLiveStreamingPage={isOpenLiveStreamingPage}
                goLive={() => goLive(video._id)}
              />

              <LiveListItemState ticket={ticket} />

              <div css={classesArchiveVideoDesign.ticketInfo}>
                <LiveListItemPrice
                  canWatch={canWatch}
                  isDonationEvent={isDonationEvent}
                  ticket={ticket}
                />

                <LiveListItemCaution
                  ticket={ticket}
                  // @ts-expect-error TS2322
                  isHiddenVODExpiredAt={isHiddenVODExpiredAt}
                />

                {/* {
                  // TODO マーダーミステリー後に修正 後ほど修正する用の差分memo
                  pathname.indexOf("-murdermystery") === -1 && (
                    <LiveListItemButton
                      isAdmin={isAdmin}
                      isOnSale={isOnSale}
                      isLotteryTicketSellingMode={isLotteryTicketSellingMode}
                      isBeforeOpenStreamingPage={
                        isBeforeOpenStreamingPage || !isOpen
                      }
                      canWatch={canWatch}
                      handleButtonAction={() =>
                        handleButtonAction(videoId, isOpen, canWatch)
                      }
                    />
                  )
                } */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default EventLiveList;

/**
 * donation type event has no purchase limit
 * @param eventId
 */
const IS_DONATION_EVENT = (eventId: string) => {
  switch (eventId) {
    case "200330-istarfes":
      return true;
    default:
      return false;
  }
};

const headingDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css`
      margin-top: 50px;
    `,
    heading: css`
      margin-bottom: 40px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        color: ${hostingBaseStyleMap.fontSecondaryColor};
        font-size: 35px;
        line-height: 1em;
        border-left: 5px solid ${hostingBaseStyleMap.accentColor};
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        color: ${hostingBaseStyleMap.fontSecondaryColor};
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    charityText: css`
      margin-bottom: 20px;
      p {
        line-height: 1.5em;
      }
    `,
  };
};

const archiveVideoDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 31%;
        }
      }
    `,
    videoItem: css`
      width: 100%;
      border: 2px solid #d6d6d6;
      @media screen and (min-width: 768px) {
        width: 31%;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `,
    ticketInfo: css`
      padding: 16px;
    `,
  };
};
